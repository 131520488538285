export default function logs(){
    console.log(logEinstein)
    console.log(logMaze)
}




const logEinstein =
    "       -''--.\n" +
    "       _`>   `\\.-'<\n" +
    "    _.'     _     '._\n" +
    "  .'   _.='   '=._   '.\n" +
    "  >_   / /_\\ /_\\ \\   _<\n" +
    "    / (  \\o/\\\\o/  ) \\\n" +
    "    >._\\ .-,_)-. /_.<\n" +
    "        /__/ \\__\\ \n" +
    "          '---'    \n"+
    "       You are curious,\n"+
    "        I Like You :)\n"


const logMaze = "           solve this instead of wasting time here :)\n" +
    "  _________________________________________________________________\n" +
    " |         ___________            |   __________________________   |\n" +
    " |  send  |   _____   |  |  |  |  |  |   ____________________   |  |\n" +
    " |________|  |     |_____|  |  |_____|  |   _________________   |  |\n" +
    " |___________|  |___________|  |   _____|  |   ___________   |  |  |\n" +
    " |   ___________|   ___________|  |  ______|  |   ________|  |_____|\n" +
    " |  |   ___________|   ___________|___________|  |___________|     |\n" +
    " |  |  |   __         |   _________________   |_____   |     |  |  |\n" +
    " |  |  |  |  |  |  |  |  |   ______________|_____   |  |  |  |  |  |\n" +
    " |  |  |  |  |  |  |_____|  |   _____   |   __   |  |  |  |  |  |  |\n" +
    " |  |  |  |  |  |___________|  |     |  |  |  |  |  |  |  |  |  |  |\n" +
    " |  |  |  |  |_________________|  |  |  |  |  |  |  |  |  |  |  |  |\n" +
    " |  |  |  |  |   _________________|_____|  |  |  |  |  |  |  |  |  |\n" +
    " |  |  |  |  |  |   __    ______________|  |  |  |  |   __|  |  |  |\n" +
    " |  |  |  |  |  |  |  |  |   ________   |  |  |  |  |  |  |  |  |  |\n" +
    " |  |  |  |  |  |  |  |  |  |   _____   |  |  |  |  |  | ____|  |  |\n" +
    " |  |  |  |  |  |  |  |  |  |  |  ______|  |  |  |  |   ________|  |\n" +
    " |  |  |_____|  |  |  |  |  |  |_____   |  |  |  |  |  |           |\n" +
    " |  |_____   |  |__|  |  |  |________|  |  |  |  |__|  |   answer  |\n" +
    " |___________|_______________________|________|________|___________|\n" +
    "                      To Me: MMMohebi@outlook.com ;) "